import type { FunctionComponent } from 'react'
import { Link, Typography } from '@which/seatbelt'

import type {
  MemberBenefitOffer,
  MemberBenefitRenewalOffer,
  MemberBenefitsInfoQuery,
} from '../../../../../../generated/frontend'
import { BenefitLink } from '../BenefitLink/BenefitLink'
import { OfferContainer } from './OfferContainer'
import styles from './PersonalisedMemberBenefitsLoggedIn.module.scss'

type PersonalisedMemberBenefitsLoggedInProps = {
  links?: MemberBenefitsInfoQuery['memberBenefitsInfo']['links']
  offer?: MemberBenefitOffer
  renewalOffer?: MemberBenefitRenewalOffer
}

export const PersonalisedMemberBenefitsLoggedIn: FunctionComponent<
  PersonalisedMemberBenefitsLoggedInProps
> = ({ links, offer, renewalOffer, ...rest }) => {
  const title = 'Get the most from your membership'
  const accountLinkText = 'your account'
  const appointmentLinkText = 'book an appointment'

  return (
    <article aria-labelledby={'member-benefits-heading'} className={styles.panel} {...rest}>
      <div className={styles.panelHeadingWrapper}>
        <Typography
          id="member-benefits-heading"
          tag="h2"
          textStyle="sb-text-heading-small"
          className={styles.panelTitle}
        >
          {title}
        </Typography>
      </div>
      {links ? (
        <div className={styles.panelSection}>
          {links.map((link, i) => {
            if (link.__typename == 'MemberBenefitPageLink') {
              return (
                <BenefitLink
                  link={link}
                  key={i}
                  trackingData={{
                    'data-which-id': 'Homepage Link',
                    'data-card-name': link.text,
                    'data-section': title,
                    'data-index': `${i + 1}`,
                  }}
                />
              )
            }
            console.error('Unexpected link type for logged in member benefits')
            return null
          })}
        </div>
      ) : null}
      <OfferContainer offer={offer} renewalOffer={renewalOffer} sectionTitle="" />
      <div className={styles.panelSection}>
        <Typography textStyle="sb-text-body-default-regular">
          See all the details of your membership in{' '}
          <Link
            href="/my-account"
            data-which-id="Homepage Link"
            data-card-name={accountLinkText}
            data-section={title}
          >
            {accountLinkText}
          </Link>{' '}
          or{' '}
          <Link
            href="/my-appointments#upcoming-appointments"
            data-which-id="Homepage Link"
            data-card-name={appointmentLinkText}
            data-section={title}
            data-index={'2'}
          >
            {appointmentLinkText}
          </Link>
        </Typography>
      </div>
    </article>
  )
}
