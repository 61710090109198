import type { ComponentPropsWithoutRef, FunctionComponent } from 'react'
import { useState } from 'react'
import { SignupButton, Typography } from '@which/seatbelt'

import classnames from 'classnames'

import type {
  MemberBenefitLink,
  MemberBenefitWidgetLink,
} from '../../../../../../generated/frontend'
import BenefitOverlay from '../BenefitOverlay/BenefitOverlay'
import { WidgetLinkList } from '../WidgetLink/WidgetLinkList'
import styles from './PersonalisedMemberBenefitsLoggedOut.module.scss'

type PersonalisedMemberBenefitsLoggedOutProps = {
  widgetLinks: MemberBenefitLink[]
  calloutText?: string
  caption: string
  overline: string
} & ComponentPropsWithoutRef<'article'>

export const PersonalisedMemberBenefitsLoggedOut: FunctionComponent<
  PersonalisedMemberBenefitsLoggedOutProps
> = ({
  widgetLinks,
  calloutText,
  caption,
  overline,
  ...rest
}: PersonalisedMemberBenefitsLoggedOutProps) => {
  const [showOverlay, setShowOverlay] = useState(false)
  const [selectedWidget, setSelectedWidget] = useState<MemberBenefitLink | null>(null)

  const onWidgetClicked = (widget: MemberBenefitWidgetLink) => {
    setShowOverlay(true)
    setSelectedWidget(widget)
  }

  const onBackButtonPressed = () => {
    setShowOverlay(false)
  }

  return (
    <article className={classnames(styles.panel)} {...rest}>
      <div>
        <Typography textStyle="sb-text-heading-overline" className={styles.panelOverline}>
          {overline}
        </Typography>
        <Typography tag="h2" textStyle="sb-text-heading-small" className={styles.panelTitle}>
          Member benefits
        </Typography>
      </div>
      <div className={styles.panelContent}>
        <div className={styles.overlayContainer}>
          <BenefitOverlay
            onBackButtonPressed={onBackButtonPressed}
            widgetLink={
              selectedWidget?.__typename == 'MemberBenefitWidgetLink' ? selectedWidget : null
            }
            hidden={!showOverlay}
          />
          <div className={styles.benefitContent}>
            <WidgetLinkList
              onWidgetClicked={onWidgetClicked}
              hidden={showOverlay}
              widgetLinks={widgetLinks}
            />
            {calloutText && (
              <div data-testid={'callout'} className={styles.callout}>
                {calloutText}
              </div>
            )}
          </div>
        </div>

        <SignupButton
          caption={caption}
          className={styles.signupButtonLink}
          buttonLink={{
            href: 'https://join.which.co.uk',
            text: 'Subscribe to Which?',
          }}
          data-which-id="benefits-panel-button"
        />
      </div>
    </article>
  )
}
